body {
    .p-dropdown {
        border: 1px solid #d6d6d6;
        width: 100%;
    
        &:not(.p-state-disabled):hover {
            background-color: #d9d9d9;
            border-color: #cccccc;

            .p-dropdown-trigger,
            .p-dropdown-clear-icon {
                background-color: #d9d9d9;
            }
        }
        
        .p-dropdown-label {
            background: #ffffff;
            padding-right: 3em;
            text-overflow: ellipsis;
        }
        
        .p-dropdown-trigger,
        .p-dropdown-clear-icon {
            background-color: #ffffff;
        }
        
        .p-dropdown-trigger .fa {
            color: #55595c;
            margin-top: .65em;
        }
    

        &:not(.p-state-disabled):hover .p-dropdown-label,
        &:not(.p-state-disabled):hover .p-dropdown-trigger,
        &:not(.p-state-disabled):hover .p-dropdown-clear-icon,

        &.p-state-focus .p-dropdown-label,
        &.p-state-focus .p-dropdown-trigger,
        &.p-state-focus .p-dropdown-clear-icon {
            -moz-border-radius: 3px;
            -webkit-border-radius: 3px;
            border-radius: 3px;
            -moz-border-top-left-radius: 0;
            -webkit-border-top-left-radius: 0;
            border-top-left-radius: 0;
            -moz-border-bottom-left-radius: 0;
            -webkit-border-bottom-left-radius: 0;
            border-bottom-left-radius: 0;
            background-color: #d9d9d9;
        }

        &.p-state-focus .p-dropdown-label.p-inputtext {
            border: 0 none;
        }
    }

    .p-dropdown-panel {
        .p-dropdown-filter-container {
            padding: 0.375em 0.75em;
        
            .p-dropdown-filter {
                width: 100%;
                box-sizing: border-box;
                padding-right: 1.250em;
            }
            
            .fa {
                top: 1.05em;
                right: 1.25em;
            }
        }
        
        .p-dropdown-items {
            padding: 0;
        
            .p-dropdown-item {
                padding: 0.375em 0.75em;
                -moz-border-radius: 0;
                -webkit-border-radius: 0;
                border-radius: 0;
            }
            
            .p-dropdown-item:not(.p-state-highlight):hover {
                background-color: #f4f3f4;
                color: #373a3c;
            }
            
            .p-dropdown-item.p-state-highlight {
                background-color: #0275d8;
                color: #ffffff;
            }
            
            .p-dropdown-item > td {
                padding: 0.375em 0.75em;
            }
            
            .p-dropdown-item-group {
                padding: 0.375em 0.75em;
                background-color: #e6e6e6;
            }
        }
    }
}