body {
    .p-button {
        color: #ffffff;
        background-color: #0275d8;
        border: 1px solid transparent;
        padding: 5px 10px;
        margin-right: 5px;
        margin-left: 5px;
        font-size: 1em;
        -moz-transition: background-color 0.3s;
        -o-transition: background-color 0.3s;
        -webkit-transition: background-color 0.3s;
        transition: background-color 0.3s;

        &.p-autocomplete-dropdown{
            margin: 0!important;
        }

        &.p-button-standalone {
            margin: 0 10px;

            & + .p-button-standalone {
                margin-left: 5px;
            }

            &:first-child {
                margin-left: 0;
            }
    
            &:last-child {
                margin-right: 0;
            }
        }

        &:focus {
            outline: 0 none;
            background-color: #0267bf;
        }

        &:enabled {
            &:hover {
                outline: 0 none;
                background-color: #0267bf;
            }
            
            &:active {
                background-color: #025aa5;
                -moz-box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.2);
                -webkit-box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.2);
                box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
            }
        }
        &.p-button-icon-only{
            height: 2.4em;
            &.p-button-xs{
                height: 2.35em;
            }

            &.p-button-xxs{
               padding: 0;
               width: 18px;
               height: 18px;
               margin-right: 1px;
               margin-left: 1px;
            }
        }
    }

    .p-button,
    .p-button.p-button-text-only {
        .p-button-text {
            padding: 0;
        }
    }
}
