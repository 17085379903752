@import './colors.scss';

.p-inputtext.ng-dirty.ng-invalid,
p-dropdown.ng-dirty.ng-invalid > .p-dropdown,
p-autocomplete.ng-dirty.ng-invalid > .p-autocomplete > .p-inputtext,
p-calendar.ng-dirty.ng-invalid > .p-inputtext,
p-chips.ng-dirty.ng-invalid > .p-inputtext,
p-inputmask.ng-dirty.ng-invalid > .p-inputtext,
p-checkbox.ng-dirty.ng-invalid .p-chkbox-box,
p-radiobutton.ng-dirty.ng-invalid .p-radiobutton-box,
p-inputswitch.ng-dirty.ng-invalid .p-inputswitch,
p-listbox.ng-dirty.ng-invalid .p-inputtext,
p-multiselect.ng-dirty.ng-invalid > .p-multiselect,
p-spinner.ng-dirty.ng-invalid > .p-inputtext,
p-selectbutton.ng-dirty.ng-invalid .p-button,
p-togglebutton.ng-dirty.ng-invalid .p-button {
    border-color: $validation-color;
}