@import './colors.scss';

body {
    .p-growl {
        .p-growl-message-success {
            background-color: $success-background-color;
            color: $success-color;
        }

        .p-growl-message-info {
            background-color: $info-background-color;
            color: $info-color;
        }

        .p-growl-message-warn {
            background-color: $warn-background-color;
            color: $warn-color;
        }

        .p-growl-message-error {
            background-color: $error-background-color;
            color: $error-color;
        }
    }
}