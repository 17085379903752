body {
    .p-radiobutton {
        display: inline-block;

        .p-radiobutton-box {
            border: 1px solid rgba(0, 0, 0, 0.15);
            background-color: #ffffff;
            -moz-border-radius: 50%;
            -webkit-border-radius: 50%;
            border-radius: 50%;

            &:not(.p-state-disabled):not(.p-state-active):hover {
                border: 1px solid #b3b3b3;
            }

            &.p-state-active {
                border: 1px solid #0275d8;
                background-color: #0275d8;
                color: #ffffff;
                
                .p-radiobutton-icon.fa-circle {
                    color: #ffffff;
                }
            }

            &.p-state-focus {
                border: 1px solid #0275d8;
                -webkit-box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.2);
                -moz-box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.2);
                box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.2);
            }

            .p-radiobutton-icon {
                font-size: 0.5em;
                overflow: hidden;
                position: absolute;
                // top: -0.15em;
            }
        }
    }
}