body .p-inputswitch {
    background-color:transparent;
    text-transform: uppercase;
}
body .p-inputswitch .p-inputswitch-handle {
    background-color: #ffffff;
    border: 1px solid transparent;
    box-sizing: border-box;
}
body .p-inputswitch .p-inputswitch-handle.p-state-focus {
    -webkit-box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.2);
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.2);
}
body .p-inputswitch .p-inputswitch-on,
body .p-inputswitch .p-inputswitch-off {
    color: #fff;
}
body .p-inputswitch .p-inputswitch-on span,
body .p-inputswitch .p-inputswitch-off span {
    margin-right: 0.2em;
    position: relative;
    top: -0.1em;
    font-weight: 600;
}