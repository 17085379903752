
@charset "UTF-8";
@import './colors.scss';
@import './p-radiobutton.scss';
@import './p-dialog.scss';
@import './p-button.scss';
@import './p-messages.scss';
@import './p-growl.scss';
@import './p-dropdown.scss';
@import './p-overlaypanel.scss';
@import './validation.scss';
@import './p-inputswitch.scss';


body .p-widget,
body .p-widget .p-widget,
body .p-widget input, body .p-widget select, body .p-widget textarea, body .p-widget button {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
    text-decoration: none;
    font-size: 1em;
}
body .p-widget-content {
    background-color: #ffffff;
    border: 1px solid #c7c7c7;
}
body .p-widget-header {
    background: #ffffff;
    color: #2d353c;
    border: 1px solid #cdcdcd;
}
body .p-state-active,
body .p-state-highlight {
    background-color: #0275d8;
    color: #ffffff;
}
body .p-state-error,
body .p-state-default.p-state-error {
    border-color: $validation-color;
}
body .p-icon {
    width: 1em;
    height: 1em;
    line-height: 1em;
}
body .p-state-disabled,
body .p-widget:disabled {
    opacity: .35; 
    filter: Alpha(Opacity= 35); 
    background-image: none; 
    cursor: default !important;
}
body .p-state-disabled *,
body .p-widget:disabled * {
    cursor: default !important;
}
body .p-corner-all {
    -moz-border-radius: 0.25em;
    -webkit-border-radius: 0.25em;
    border-radius: 0.25em;
}
body .p-corner-top {
    -moz-border-radius-topleft: 0.25em;
    -webkit-border-top-left-radius: 0.25em;
    border-top-left-radius: 0.25em;
    -moz-border-radius-topright: 0.25em;
    -webkit-border-top-right-radius: 0.25em;
    border-top-right-radius: 0.25em;
}
body .p-corner-bottom {
    -moz-border-radius-bottomleft: 0.25em;
    -webkit-border-bottom-left-radius: 0.25em;
    border-bottom-left-radius: 0.25em;
    -moz-border-radius-bottomright: 0.25em;
    -webkit-border-bottom-right-radius: 0.25em;
    border-bottom-right-radius: 0.25em;
}
body .p-corner-left {
    -moz-border-radius-topleft: 0.25em;
    -webkit-border-top-left-radius: 0.25em;
    border-top-left-radius: 0.25em;
    -moz-border-radius-bottomleft: 0.25em;
    -webkit-border-bottom-left-radius: 0.25em;
    border-bottom-left-radius: 0.25em;
}
body .p-corner-right {
    -moz-border-radius-topright: 0.25em;
    -webkit-border-top-right-radius: 0.25em;
    border-top-right-radius: 0.25em;
    -moz-border-radius-bottomright: 0.25em;
    -webkit-border-bottom-right-radius: 0.25em;
    border-bottom-right-radius: 0.25em;
}
body .p-widget-overlay {
    background-color: #424242;
    opacity: 0.7;
    filter: alpha(opacity=70);
}
body .p-inputtext {
    background: #ffffff;
    padding: 0.5em 0.75em;
    border: 1px solid rgba(0, 0, 0, 0.15);
    -moz-border-radius: 0.25em;
    -webkit-border-radius: 0.25em;
    border-radius: 0.25em;
    font-size: 1em;
    color: #55595c;
    line-height: 1.25;
}
body .p-inputtext.p-state-focus,
body .p-inputtext:focus {
    border: 1px solid #66afe9;
}


body .p-fileupload-choose:not(.p-state-disabled):hover,
body .p-fileupload-choose.p-state-focus {
    outline: 0 none;
    background-color: #0267bf;
}

body .p-fileupload-choose:not(.p-state-disabled):active {
    background-color: #025aa5;
    -moz-box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.2);
    -webkit-box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.2);
    box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
}

body .p-button.raised-btn {
    -moz-box-shadow: 0 1px 2.5px 0 rgba(0, 0, 0, 0.26), 0 1px 5px 0 rgba(0, 0, 0, 0.16);
    -webkit-box-shadow: 0 1px 2.5px 0 rgba(0, 0, 0, 0.26), 0 1px 5px 0 rgba(0, 0, 0, 0.16);
    box-shadow: 0 1px 2.5px 0 rgba(0, 0, 0, 0.26), 0 1px 5px 0 rgba(0, 0, 0, 0.16);
}
body .p-togglebutton.p-button.p-state-focus {
    -moz-box-shadow: 0px 0px 5px #1f89ce;
	-webkit-box-shadow: 0px 0px 5px #1f89ce;
	box-shadow: 0px 0px 5px #1f89ce;
}
body .p-togglebutton:not(.p-state-disabled):not(.p-state-active):hover {
    background-color: #0267bf;
}
body .p-togglebutton:not(.p-state-disabled).p-state-active {
    background-color: #025aa5;
}
body .p-chips .p-chips-token .p-chips-token-label {
    padding: 0.125em;
}
body .p-chips .p-chips-input-token input:focus {
    border: none;
}
body .p-chkbox .p-chkbox-box {
    border: 1px solid rgba(0, 0, 0, 0.15);
    background-color: #ffffff;
    -moz-transition: background-color 0.3s;
    -o-transition: background-color 0.3s;
    -webkit-transition: background-color 0.3s;
    transition: background-color 0.3s;
}
body .p-chkbox .p-chkbox-box:not(.p-state-disabled):not(.p-state-active):hover {
    border: 1px solid #b3b3b3;
}
body .p-chkbox .p-chkbox-box.p-state-active {
    border: 1px solid #0275d8;
    background-color: #0275d8;
    color: #ffffff;
}
body .p-chkbox .p-chkbox-box.p-state-focus {
    border: 1px solid #0275d8;
    -webkit-box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.2);
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.2);
}

body .p-selectbutton.p-buttonset .p-button {
    background-color: #ffffff;
    border-color: #cccccc;
}
body .p-selectbutton.p-buttonset .p-button:not(.p-state-disabled):not(.p-state-active):hover {
    background-color: #e6e6e6;
    color: #373a3c;
}
body .p-selectbutton.p-buttonset .p-button.p-state-active {
    background-color: #0275d8;
    color: #ffffff;
}
body .p-autocomplete .p-autocomplete-dropdown .p-button-text {
    padding: 0.35em;
}
body .p-autocomplete.p-autocomplete-multiple .p-autocomplete-multiple-container {
    padding: 0.5em 0.75em;
}
body .p-autocomplete.p-autocomplete-multiple .p-autocomplete-multiple-container .p-autocomplete-input-token input:focus {
    border: none;
}
body .p-autocomplete-panel {
    -webkit-box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.3);
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.3);
}
body .p-autocomplete-panel .p-autocomplete-items {
    padding: 0;
}
body .p-autocomplete-panel .p-autocomplete-items .p-autocomplete-list-item {
    margin: 0;
    padding: 0.375em 0.75em;
    -moz-border-radius: 0;
    -webkit-border-radius: 0;
    border-radius: 0;
}
body .p-autocomplete-panel .p-autocomplete-items .p-autocomplete-group {
    padding: 0.375em 0.75em;
    background-color: #e6e6e6;
    color: #373a3c;
}
body .p-multiselect {
    background: #ffffff;
    padding: 0.5em 0.75em;
    border: 1px solid #e6e6e6;
    -moz-border-radius: 0.25em;
    -webkit-border-radius: 0.25em;
    border-radius: 0.25em;
}
body .p-multiselect .p-multiselect-trigger {
     -moz-border-radius: 3px;
    -webkit-border-radius: 3px;
    border-radius: 3px;
    -moz-border-top-left-radius: 0;
    -webkit-border-top-left-radius: 0;
    border-top-left-radius: 0;
    -moz-border-bottom-left-radius: 0;
    -webkit-border-bottom-left-radius: 0;
    border-bottom-left-radius: 0;
}
body .p-multiselect:not(.p-state-disabled):hover {
    color: #212121;
}
body .p-multiselect:not(.p-state-disabled):hover .p-multiselect-trigger {
    background-color: #d9d9d9;
}
body .p-multiselect .p-multiselect-trigger .fa {
    margin-top: .8em;
}
body .p-multiselect:not(.p-state-disabled) .p-multiselect-trigger:hover {
    background-color: #d9d9d9;
}
body .p-multiselect .p-multiselect-panel {
    padding: 0;
}
body .p-multiselect .p-multiselect-header {
    border-top: 0 none;
    border-left: 0 none;
    border-right: 0 none;
    -moz-border-radius: 0;
    -webkit-border-radius: 0;
    border-radius: 0;
    padding: 0.375em 0.75em;
}
body .p-multiselect .p-multiselect-header .p-multiselect-close {
    color: #373a3c;
}
body .p-multiselect .p-multiselect-item {
    margin: 0;
    padding: 0.375em 0.75em;
    -moz-border-radius: 0;
    -webkit-border-radius: 0;
    border-radius: 0;
}
body .p-multiselect .p-multiselect-item:not(.p-state-highlight):hover {
    background-color: #f4f3f4;
    color: #373a3c;
}
body .p-multiselect .p-multiselect-item.p-state-highlight {
    background-color: #0275d8;
    color: #ffffff;
}
body .p-multiselect .p-multiselect-item.p-state-highlight .p-chkbox {
    border: 1px solid #108ffd;
}
body .p-multiselect .p-multiselect-item > td {
    padding: 0.375em 0.75em;
}
body .p-listbox {
    padding: 0;
    width: 12.5em;
}
body .p-listbox .p-listbox-item {
    margin: 0;
    padding: 0.375em 0.75em;
    -moz-border-radius: 0;
    -webkit-border-radius: 0;
    border-radius: 0;
}
body .p-listbox .p-listbox-item:last-child {
    border-bottom: none;
}
body .p-listbox:not(.p-state-disabled) .p-listbox-item:hover {
    background-color: #f4f3f4;
    color: #373a3c;
}
body .p-listbox .p-listbox-item.p-state-highlight {
    background-color: #0275d8;
    color: #ffffff;
}
body .p-listbox .p-listbox-item.p-state-highlight .p-chkbox {
    border: 1px solid #108ffd;
}
body .p-listbox.p-state-disabled .p-chkbox-box:not(.p-state-active):hover {
    border: 1px solid rgba(0, 0, 0, 0.15);
    background-color: #ffffff;
}
body .p-listbox .p-listbox-item > td {
    padding: 0.375em 0.75em;
}
body .p-listbox .p-listbox-header {
    padding: 0.375em 0.75em;
    border-top: 0 none;
    border-right: 0 none;
    border-left: 0 none;
    -moz-border-radius: 0;
    -webkit-border-radius: 0;
    border-radius: 0;
}
body .p-listbox .p-listbox-header .p-listbox-filter-container {
    width: 8em;
}
body .p-multiselectlistbox {
    padding: 0;
}
body .p-multiselectlistbox .p-multiselectlistbox-header {
    padding: 0.375em 0.75em;
}
body .p-multiselectlistbox .p-multiselectlistbox-item {
    margin: 0;
    padding: 0.375em 0.75em;
    -moz-border-radius: 0;
    -webkit-border-radius: 0;
    border-radius: 0;
}
body .p-multiselectlistbox .p-multiselectlistbox-item:not(.p-state-highlight):hover {
    background-color: #f4f3f4;
    color: #373a3c;
}
body .p-multiselectlistbox .p-multiselectlistbox-item.p-state-highlight {
    background-color: #0275d8;
    color: #ffffff;
}
body .p-rating .p-rating-cancel {
    display: inline-block;
}
body .p-rating .p-rating-cancel a {
    background: none !important;
    display: inline-block;
    font: normal normal normal 14px/1 FontAwesome;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-indent: 0px !important;
    text-align: center;
    display: block;
    -moz-transition: color 0.3s;
    -o-transition: color 0.3s;
    -webkit-transition: color 0.3s;
    transition: color 0.3s;
    color: #e42a7b;
}
body .p-rating .p-rating-cancel a::before {
    content: "";
}
body .p-rating .p-rating-cancel a:hover {
    color: #e74189;
}
body .p-rating .p-rating-star {
    display: inline-block;
}
body .p-rating .p-rating-star a {
    background: none !important;
    display: inline-block;
    font: normal normal normal 14px/1 FontAwesome;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-indent: 0px !important;
    text-align: center;
    display: block;
    color: #373a3c;
}
body .p-rating .p-rating-star a::before {
    content: "";
}
body .p-rating .p-rating-star a:hover {
    color: #0275d8;
}
body .p-rating .p-rating-star-on a {
    color: #0275d8;
    background: none !important;
    display: inline-block;
    font: normal normal normal 14px/1 FontAwesome;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-indent: 0px !important;
    text-align: center;
    display: block;
}
body .p-rating .p-rating-star-on a::before {
    content: "";
}
body .p-spinner .p-spinner-button {
    z-index: auto;
    box-sizing: border-box;
}
body .p-spinner:not(.p-state-disabled) .p-spinner-button:hover {
    background-color: #0267bf;
    color: #ffffff;
}
body .p-spinner:not(.p-state-disabled) .p-spinner-button:active {
    background-color: #025aa5;
    color: #ffffff;
}
body .p-slider {
    background-color: #e6e6e6;
    border-color: #e6e6e6;
}
body .p-slider.p-slider-horizontal .p-slider-handle {
    top: -.2em;
}
body .p-slider.p-slider-vertical .p-slider-handle {
    left: -.2em;
}
body .p-slider .p-slider-handle {
    background-color: #0275d8;
    -moz-transition: background-color 0.3s;
    -o-transition: background-color 0.3s;
    -webkit-transition: background-color 0.3s;
    transition: background-color 0.3s;
    -moz-border-radius: 100%;
    -webkit-border-radius: 100%;
    border-radius: 100%;
}
body .p-slider.p-slider-animate .p-slider-handle {
    -moz-transition: background-color 0.3s,left 0.3s;
    -o-transition: background-color 0.3s,left 0.3s;
    -webkit-transition: background-color 0.3s,left 0.3s;
    transition: background-color 0.3s,left 0.3s;
}
body .p-slider .p-slider-handle:hover {
    background-color: #0267bf;
}
body .p-slider .p-slider-range {
    background: #43a7fd;
}
body .p-buttonset.p-selectbutton .p-button:first-child {
    border-right: none;
}
body .p-buttonset.p-selectbutton .p-button:last-child {
    border-left: none;
}
body .p-buttonset .p-button {
    background-color: #e6e6e6;
    color: #373a3c;
    border: 1px solid transparent;
    -moz-border-radius: 0px;
    -webkit-border-radius: 0px;
    border-radius: 0px;
}
body .p-buttonset .p-button:hover {
    background-color: #cdcdcd;
}
body .p-buttonset .p-button:focus {
    background-color: #e6e6e6;
    color: #373a3c;
    -webkit-box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.2);
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.2);
    border: 1px solid #0275d8;
}
body .p-buttonset .p-button:active {
    background-color: #0275d8;
    color: #ffffff;
}
body .p-buttonset .p-button:active:focus {
    background-color: #0275d8;
    color: #ffffff;
    -webkit-box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.2);
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.2);
}
body .p-buttonset .p-button:first-child {
    -moz-border-radius-topleft: 0.25em;
    -webkit-border-top-left-radius: 0.25em;
    border-top-left-radius: 0.25em;
    -moz-border-radius-bottomleft: 0.25em;
    -webkit-border-bottom-left-radius: 0.25em;
    border-bottom-left-radius: 0.25em;
}
body .p-buttonset .p-button:last-child {
    -moz-border-radius-topright: 0.25em;
    -webkit-border-top-right-radius: 0.25em;
    border-top-right-radius: 0.25em;
    -moz-border-radius-bottomright: 0.25em;
    -webkit-border-bottom-right-radius: 0.25em;
    border-bottom-right-radius: 0.25em;
}
@media (max-width: 640px) {
    body .p-buttonset .p-button {
        margin-bottom: 0.063em;
        -moz-border-radius: 0.25em;
        -webkit-border-radius: 0.25em;
        border-radius: 0.25em;
    }
}
body .p-splitbutton.p-buttonset .p-button {
    color: #ffffff;
    background-color: #0275d8;
    border: 1px solid transparent;
}
body .p-splitbutton.p-buttonset .p-button.p-splitbutton-menubutton {
    -moz-border-radius-topright: 0.25em;
    -webkit-border-top-right-radius: 0.25em;
    border-top-right-radius: 0.25em;
    -moz-border-radius-bottomright: 0.25em;
    -webkit-border-bottom-right-radius: 0.25em;
    border-bottom-right-radius: 0.25em;
}
body .p-splitbutton.p-buttonset .p-button.p-splitbutton-menubutton .fa {
    margin-left: -.65em;
}
body .p-splitbutton.p-buttonset .p-button:hover {
    background-color: #0267bf;
    color: #ffffff;
}
body .p-splitbutton.p-buttonset .p-button.p-state-focus {
    background-color: #0267bf;
    color: #ffffff;
}
body .p-splitbutton.p-buttonset .p-button.p-state-active {
    background-color: #025aa5;
    color: #ffffff;
}
body .p-datepicker {
    padding: 0;
}
body .p-datepicker .p-datepicker-header {
    padding: .75em 0;
    background-color: #f5f5f5;
    border: 0;
    border-bottom: 1px solid #c7c7c7;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}
body .p-datepicker .p-datepicker-header .p-datepicker-prev {
    cursor: pointer;
    color: #767b7f;
    background: none !important;
    display: inline-block;
    font: normal normal normal 14px/1 FontAwesome;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-indent: 0px !important;
    text-align: center;
    -moz-transition: color 0.3s;
    -o-transition: color 0.3s;
    -webkit-transition: color 0.3s;
    transition: color 0.3s;
    top: 1.25em;
}
body .p-datepicker .p-datepicker-header .p-datepicker-prev::before {
    content: "";
}
body .p-datepicker .p-datepicker-header .p-datepicker-prev span {
    display: none;
}
body .p-datepicker .p-datepicker-header .p-datepicker-prev.p-datepicker-prev-hover {
    color: #2d353c;
}
body .p-datepicker .p-datepicker-header .p-datepicker-next {
    cursor: pointer;
    color: #767b7f;
    background: none !important;
    display: inline-block;
    font: normal normal normal 14px/1 FontAwesome;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-indent: 0px !important;
    text-align: center;
    -moz-transition: color 0.3s;
    -o-transition: color 0.3s;
    -webkit-transition: color 0.3s;
    transition: color 0.3s;
    top: 1.25em;
}
body .p-datepicker .p-datepicker-header .p-datepicker-next::before {
    content: "";
}
body .p-datepicker .p-datepicker-header .p-datepicker-next span {
    display: none;
}
body .p-datepicker .p-datepicker-header .p-datepicker-next.p-datepicker-next-hover {
    color: #2d353c;
}
body .p-datepicker table {
    font-size: 1em;
}
body .p-datepicker table td a {
    min-width: 1em;
    text-align: center;
    color: #373a3c;
    font-size: .9em;
}
body .p-datepicker .p-datepicker-calendar td:not(.p-state-disabled) a:hover {
    background-color: #f4f3f4;
}
body .p-datepicker table.p-datepicker-calendar td:not(.p-state-disabled) a.p-state-highlight {
    color: #373a3c;
    background-color: #e6e6e6;
}
body .p-datepicker table.p-datepicker-calendar td:not(.p-state-disabled) a.p-state-active {
    color: #ffffff;
    background-color: #0275d8;
}
body .p-organizationchart .p-organizationchart-line-down{
    background-color: #bcbcbc;
}
body .p-organizationchart .p-organizationchart-line-left{
    border-right: 1px solid #bcbcbc;
}
body .p-organizationchart .p-organizationchart-line-top{
    border-top: 1px solid #bcbcbc;
}
body .p-organizationchart .p-organizationchart-node-content{
    border-color: #bcbcbc;
}
body .p-organizationchart .p-organizationchart-node-content .p-node-toggler{
    color: #bcbcbc;
}
body .p-trigger-calendar .p-datepicker-trigger.p-button {
    -moz-border-radius-topleft: 0;
    -webkit-border-top-left-radius: 0;
    border-top-left-radius: 0;
    -moz-border-radius-bottomleft: 0;
    -webkit-border-bottom-left-radius: 0;
    border-bottom-left-radius: 0;
}
body .p-inplace .p-inplace-display {
    padding: 0.5em 0.75em;
    -moz-border-radius: 0.25em;
    -webkit-border-radius: 0.25em;
    border-radius: 0.25em;
}
body .p-inplace .p-inplace-display:hover {
    background-color: #f4f3f4;
}
body .p-fileupload .p-fileupload-buttonbar {
    padding: .75em 1.25em;
    background-color: #f5f5f5;
    border-bottom: 0 none;
}
body .p-fileupload .p-fileupload-content {
    padding: 1.25em;
}
body .p-panel {
    padding: 0;
    border-color: rgba(0, 0, 0, 0.125);
}
body .p-panel .p-panel-titlebar {
    border: 0 none;
    border-bottom: 1px solid #e6e6e6;
    padding: .75em 1.25em;
    background-color: #f5f5f5;
    -moz-border-radius-topleft: 0.25em;
    -webkit-border-top-left-radius: 0.25em;
    border-top-left-radius: 0.25em;
    -moz-border-radius-topright: 0.25em;
    -webkit-border-top-right-radius: 0.25em;
    border-top-right-radius: 0.25em;
    -moz-border-radius-bottomleft: 0px;
    -webkit-border-bottom-left-radius: 0px;
    border-bottom-left-radius: 0px;
    -moz-border-radius-bottomright: 0px;
    -webkit-border-bottom-right-radius: 0px;
    border-bottom-right-radius: 0px;
}
body .p-panel .p-panel-titlebar .p-panel-titlebar-icon {
    position: relative;
    color: #767b7f;
    top: 0.125em;
    -moz-transition: color 0.3s;
    -o-transition: color 0.3s;
    -webkit-transition: color 0.3s;
    transition: color 0.3s;
}
body .p-panel .p-panel-titlebar .p-panel-titlebar-icon:hover {
    color: #2d353c;
}
body .p-panel .p-panel-content {
    height: 100%;
    box-sizing: border-box;
    padding: 1.25em;
}
body .p-panel .p-panel-footer {
    padding: 0.375em 0.75em;
    border: 0 none;
    border-top: 1px solid #c7c7c7;
    margin: 0;
}
body .p-fieldset {
    border-color: #e6e6e6;
}
body .p-fieldset .p-fieldset-legend {
    color: #2d353c;
    background-color: #ffffff;
    border: 1px solid #e6e6e6;
    -moz-transition: background-color 0.3s;
    -o-transition: background-color 0.3s;
    -webkit-transition: background-color 0.3s;
    transition: background-color 0.3s;
}
body .p-fieldset .p-fieldset-legend .p-fieldset-toggler {
    display: inline-block;
    color: #767b7f;
}
body .p-fieldset .p-fieldset-legend:hover {
    background-color: #f4f3f4;
}
body .p-fieldset .p-fieldset-legend:hover .p-fieldset-toggler {
    color: #2d353c;
}
body .p-fieldset .p-fieldset-legend.p-state-focus {
    background-color: #e6e6e6;
}
body .p-fieldset .p-fieldset-content {
    padding: 0.375em 0.75em;
}
body .p-notificationbar {
    background-color: #0275d8;
    color: #ffffff;
}
body .p-accordion .p-accordion-header {
    background-color: #ffffff;
    border: 1px solid #e6e6e6;
    color: #0275d8;
    -moz-transition: background-color 0.3s;
    -o-transition: background-color 0.3s;
    -webkit-transition: background-color 0.3s;
    transition: background-color 0.3s;
}

body .p-accordion .p-accordion-header a {
    padding: .75em 1.25em .75em 2em;
}

body .p-accordion .p-accordion-header:not(.p-state-active):not(.p-state-disabled):hover {
    background-color: #f4f3f4;
}
body .p-accordion .p-accordion-header:not(.p-state-active):not(.p-state-disabled):hover .p-icon {
    color: #2d353c;
}
body .p-accordion .p-accordion-header.p-state-active {
    background-color: #0275d8;
    color: #ffffff;
}
body .p-accordion .p-accordion-header.p-state-active a {
    color: #ffffff;
}
body .p-accordion .p-accordion-header.p-tabview-outline {
    border: 0 none;
    outline: 0 none;
    background-color: #e6e6e6;
}
body .p-accordion .p-accordion-header.p-tabview-outline.p-state-active {
    background-color: #0283f1;
}
body .p-accordion .p-accordion-content {
    padding: 1.25em;
}
body .p-tabview {
    padding: 0;
    border-bottom: 1px solid #c7c7c7;
}
body .p-tabview.p-tabview-top,
body .p-tabview.p-tabview-bottom,
body .p-tabview.p-tabview-left,
body .p-tabview.p-tabview-right {
    border: 0 none;
}
body .p-tabview.p-tabview-top .p-tabview-nav,
body .p-tabview.p-tabview-bottom .p-tabview-nav,
body .p-tabview.p-tabview-left .p-tabview-nav,
body .p-tabview.p-tabview-right .p-tabview-nav {
    padding: 0;
    border: 0 none;
    background: #ffffff;
    -moz-border-radius: 0;
    -webkit-border-radius: 0;
    border-radius: 0;
    border-bottom: 1px solid #cccccc;
}
body .p-tabview.p-tabview-top .p-tabview-nav li,
body .p-tabview.p-tabview-bottom .p-tabview-nav li,
body .p-tabview.p-tabview-left .p-tabview-nav li,
body .p-tabview.p-tabview-right .p-tabview-nav li {
    position: static;
    -moz-transition: background-color 0.3s;
    -o-transition: background-color 0.3s;
    -webkit-transition: background-color 0.3s;
    transition: background-color 0.3s;
    -moz-border-radius: 0;
    -webkit-border-radius: 0;
    border-radius: 0;
    margin: 0;
    box-sizing: border-box;
    background-color: #ffffff;
    border: 1px solid transparent;
    border-bottom: none;
}
body .p-tabview.p-tabview-top .p-tabview-nav li.p-state-default a,
body .p-tabview.p-tabview-bottom .p-tabview-nav li.p-state-default a,
body .p-tabview.p-tabview-left .p-tabview-nav li.p-state-default a,
body .p-tabview.p-tabview-right .p-tabview-nav li.p-state-default a {
    padding: 0.375em 0.75em;
    color: #2d353c;
}
body .p-tabview.p-tabview-top .p-tabview-nav li.p-state-default a:focus,
body .p-tabview.p-tabview-bottom .p-tabview-nav li.p-state-default a:focus,
body .p-tabview.p-tabview-left .p-tabview-nav li.p-state-default a:focus,
body .p-tabview.p-tabview-right .p-tabview-nav li.p-state-default a:focus {
    outline: 0 none;
}
body .p-tabview.p-tabview-top .p-tabview-nav li:not(.p-state-active):not(.p-state-disabled):hover,
body .p-tabview.p-tabview-bottom .p-tabview-nav li:not(.p-state-active):not(.p-state-disabled):hover,
body .p-tabview.p-tabview-left .p-tabview-nav li:not(.p-state-active):not(.p-state-disabled):hover,
body .p-tabview.p-tabview-right .p-tabview-nav li:not(.p-state-active):not(.p-state-disabled):hover {
    border-color: #dedede;
    border-bottom: none;
    background-color: #ffffff;
}
body .p-tabview.p-tabview-top .p-tabview-nav li.p-tabview-outline,
body .p-tabview.p-tabview-bottom .p-tabview-nav li.p-tabview-outline,
body .p-tabview.p-tabview-left .p-tabview-nav li.p-tabview-outline,
body .p-tabview.p-tabview-right .p-tabview-nav li.p-tabview-outline {
    outline: 0 none;
    background-color: #e6e6e6;
}
body .p-tabview.p-tabview-top .p-tabview-nav li.p-state-active,
body .p-tabview.p-tabview-bottom .p-tabview-nav li.p-state-active,
body .p-tabview.p-tabview-left .p-tabview-nav li.p-state-active,
body .p-tabview.p-tabview-right .p-tabview-nav li.p-state-active {
    background-color: #0275d8;
    border-color: #0275d8;
}
body .p-tabview.p-tabview-top .p-tabview-nav li.p-state-active a,
body .p-tabview.p-tabview-bottom .p-tabview-nav li.p-state-active a,
body .p-tabview.p-tabview-left .p-tabview-nav li.p-state-active a,
body .p-tabview.p-tabview-right .p-tabview-nav li.p-state-active a {
    color: #ffffff;
}
body .p-tabview.p-tabview-top .p-tabview-nav li.p-state-active.p-tabview-outline,
body .p-tabview.p-tabview-bottom .p-tabview-nav li.p-state-active.p-tabview-outline,
body .p-tabview.p-tabview-left .p-tabview-nav li.p-state-active.p-tabview-outline,
body .p-tabview.p-tabview-right .p-tabview-nav li.p-state-active.p-tabview-outline {
    background-color: #0283f1;
}
body .p-tabview.p-tabview-top .p-tabview-navscroller-btn,
body .p-tabview.p-tabview-bottom .p-tabview-navscroller-btn,
body .p-tabview.p-tabview-left .p-tabview-navscroller-btn,
body .p-tabview.p-tabview-right .p-tabview-navscroller-btn {
    background-color: #ffffff;
}
body .p-tabview.p-tabview-top .p-tabview-navscroller-btn:not(.p-state-active):not(.p-state-disabled):hover,
body .p-tabview.p-tabview-bottom .p-tabview-navscroller-btn:not(.p-state-active):not(.p-state-disabled):hover,
body .p-tabview.p-tabview-left .p-tabview-navscroller-btn:not(.p-state-active):not(.p-state-disabled):hover,
body .p-tabview.p-tabview-right .p-tabview-navscroller-btn:not(.p-state-active):not(.p-state-disabled):hover {
    background-color: #f4f3f4;
}
body .p-tabview.p-tabview-top .p-tabview-navscroller-btn.p-tabview-navscroller-btn-left,
body .p-tabview.p-tabview-bottom .p-tabview-navscroller-btn.p-tabview-navscroller-btn-left,
body .p-tabview.p-tabview-left .p-tabview-navscroller-btn.p-tabview-navscroller-btn-left,
body .p-tabview.p-tabview-right .p-tabview-navscroller-btn.p-tabview-navscroller-btn-left {
    left: 0;
    height: 2.063em;
}
body .p-tabview.p-tabview-top .p-tabview-navscroller-btn.p-tabview-navscroller-btn-right,
body .p-tabview.p-tabview-bottom .p-tabview-navscroller-btn.p-tabview-navscroller-btn-right,
body .p-tabview.p-tabview-left .p-tabview-navscroller-btn.p-tabview-navscroller-btn-right,
body .p-tabview.p-tabview-right .p-tabview-navscroller-btn.p-tabview-navscroller-btn-right {
    right: 0;
    height: 2.125em;
}
body .p-tabview.p-tabview-top .p-tabview-navscroller-btn .p-icon,
body .p-tabview.p-tabview-bottom .p-tabview-navscroller-btn .p-icon,
body .p-tabview.p-tabview-left .p-tabview-navscroller-btn .p-icon,
body .p-tabview.p-tabview-right .p-tabview-navscroller-btn .p-icon {
    margin-top: 0.5em;
}
body .p-tabview.p-tabview-top .p-tabview-nav li {
    margin-right: 0.125em;
    -moz-border-radius-topleft: 0.25em;
    -webkit-border-top-left-radius: 0.25em;
    border-top-left-radius: 0.25em;
    -moz-border-radius-topright: 0.25em;
    -webkit-border-top-right-radius: 0.25em;
    border-top-right-radius: 0.25em;
}
body .p-tabview.p-tabview-left .p-tabview-nav,
body .p-tabview.p-tabview-right .p-tabview-nav {
    border-bottom: none;
}
body .p-tabview.p-tabview-left .p-tabview-nav li,
body .p-tabview.p-tabview-right .p-tabview-nav li {
    border: 1px solid transparent;
    width: 100%;
}
body .p-tabview.p-tabview-left .p-tabview-nav li:hover,
body .p-tabview.p-tabview-right .p-tabview-nav li:hover {
    border: 1px solid #dedede;
}
body .p-tabview.p-tabview-left .p-tabview-nav li.p-state-active,
body .p-tabview.p-tabview-right .p-tabview-nav li.p-state-active {
    border-color: #0275d8;
    border-bottom: none;
}
body .p-tabview.p-tabview-bottom .p-tabview-nav {
    border-bottom: none;
    border-top: 1px solid #cccccc;
}
body .p-tabview.p-tabview-bottom .p-tabview-nav li {
    -moz-border-radius-bottomleft: 4px;
    -webkit-border-bottom-left-radius: 4px;
    border-bottom-left-radius: 4px;
    -moz-border-radius-bottomright: 4px;
    -webkit-border-bottom-right-radius: 4px;
    border-bottom-right-radius: 4px;
    margin-right: 0.125em;
}
body .p-tabview.p-tabview-bottom .p-tabview-nav li:hover {
    border-bottom: 1px solid #cccccc;
    border-top-color: transparent;
}
body .p-tabview.p-tabview-left .p-tabview-nav {
    border-right: 1px solid #cccccc;
}
body .p-tabview.p-tabview-left .p-tabview-nav li {
    -moz-border-radius-topleft: 4px;
    -webkit-border-top-left-radius: 4px;
    border-top-left-radius: 4px;
    -moz-border-radius-bottomleft: 4px;
    -webkit-border-bottom-left-radius: 4px;
    border-bottom-left-radius: 4px;
}
body .p-tabview.p-tabview-left .p-tabview-nav li:hover {
    border-right: none;
}
body .p-tabview.p-tabview-right .p-tabview-nav {
    border-left: 1px solid #cccccc;
    height: 9.375em;
}
body .p-tabview.p-tabview-right .p-tabview-nav li {
    -moz-border-radius-topright: 4px;
    -webkit-border-top-right-radius: 4px;
    border-top-right-radius: 4px;
    -moz-border-radius-bottomright: 4px;
    -webkit-border-bottom-right-radius: 4px;
    border-bottom-right-radius: 4px;
}
body .p-tabview.p-tabview-right .p-tabview-nav li:hover {
    border-left-color: transparent;
}
body .p-tabview .p-tabview-panel {
    padding: 0.375em 0.75em;
}
body .p-scrollpanel .p-scrollpanel-drag {
    background-color: #e3e3e3;
}
body .p-scrollpanel .p-scrollpanel-track {
    background-color: #f7f7f7;
    border: 0 none;
}
body .p-scrollpanel .p-scrollpanel-corner {
    background-color: #f7f7f7;
}
body .p-toolbar {
    padding: 0.375em 0.75em;
}
body .p-toolbar .fa-ellipsis-v {
    color: #aaaaaa;
    margin: 0 0.5em;
}
body .p-tooltip .p-tooltip-arrow {
    color: #2d353c;
}
body .p-tooltip .p-tooltip-text {
    background-color: #2d353c;
}
@media (max-width: 640px) {
    body .p-panelgrid .p-grid-responsive .p-grid-row {
        border: 0 none;
    }
}
body .p-paginator {
    background-color: #ffffff;
    padding: 0;
    box-sizing: border-box;
}
body .p-paginator .p-paginator-first,
body .p-paginator .p-paginator-prev,
body .p-paginator .p-paginator-next,
body .p-paginator .p-paginator-last {
    color: #0275d8;
    -moz-transition: background-color 0.3s;
    -o-transition: background-color 0.3s;
    -webkit-transition: background-color 0.3s;
    transition: background-color 0.3s;
    min-height: 0.875em;
    min-width: 2em;
    padding: 0.5em 0.5em;
    vertical-align: top;
}
body .p-paginator .p-paginator-first:hover,
body .p-paginator .p-paginator-prev:hover,
body .p-paginator .p-paginator-next:hover,
body .p-paginator .p-paginator-last:hover {
    background-color: #f4f3f4;
}
body .p-paginator .p-paginator-first::before,
body .p-paginator .p-paginator-prev::before,
body .p-paginator .p-paginator-next::before,
body .p-paginator .p-paginator-last::before {
    position: relative;
    top: 0.063em;
}
body .p-paginator .p-paginator-first {
    -moz-border-radius: 0;
    -webkit-border-radius: 0;
    border-radius: 0;
}
body .p-paginator .p-paginator-prev {
    -moz-border-radius: 0;
    -webkit-border-radius: 0;
    border-radius: 0;
}
body .p-paginator .p-paginator-next {
    -moz-border-radius: 0;
    -webkit-border-radius: 0;
    border-radius: 0;
}
body .p-paginator .p-paginator-last {
    -moz-border-radius: 0;
    -webkit-border-radius: 0;
    border-radius: 0;
}
body .p-paginator .p-paginator-pages {
    padding: 0;
}
body .p-paginator .p-paginator-pages .p-paginator-page {
    padding: 0.5em 0.625em;
    min-width: 2em;
    color: #0275d8;
    -moz-transition: background-color 0.3s;
    -o-transition: background-color 0.3s;
    -webkit-transition: background-color 0.3s;
    transition: background-color 0.3s;
    border-radius: 0;
}
body .p-paginator .p-paginator-pages .p-paginator-page.p-state-active {
    color: #ffffff;
}
body .p-paginator .p-paginator-pages .p-paginator-page:hover {
    background-color: #f4f3f4;
}
body .p-paginator .p-paginator-pages .p-paginator-page:hover.p-state-active {
    background-color: #0275d8;
}
body .p-datatable .p-datatable-header,
body .p-datatable .p-datatable-footer {
    padding: .75em 1.25em;
    background-color: #f5f5f5;
}
body .p-datatable .p-editable-column.p-cell-editing .p-cell-editor {
    font-size: medium;
}
body .p-datatable .p-paginator {
    padding: 0;
}
body .p-datatable .p-datatable-thead > tr > th,
body .p-datatable .p-datatable-tfoot > tr > td {
    background-color: #ffffff;
    padding: .75em;
    border-color: #c7c7c7;
    color: #373a3c;
}
body .p-datatable thead th.p-sortable-column:not(.p-state-active):hover {
    background-color: #e3e3e3;
}
body .p-datatable thead th.p-state-active {
    background-color: #0275d8;
    border-color: #0267bf;
    color: #ffffff;
}
body .p-datatable thead th.p-state-active .p-dropdown-item {
    color: #373a3c;
}
body .p-datatable thead th:focus {
    outline: 0 none;
    background-color: #f0f3f5;
}
body .p-datatable thead th:focus.p-state-active {
    background-color: #0283f1;
}
body .p-datatable tfoot th.p-state-default {
    background-color: #ffffff;
    padding: 0.375em 0.75em;
    border-color: #c7c7c7;
    color: #373a3c;
}
body .p-datatable .p-datatable-data tr.p-datatable-even {
    background-color: #f7f7f7;
}
body .p-datatable .p-datatable-data tr.p-datatable-even.p-state-highlight {
    background-color: #0275d8;
    color: #ffffff;
    border-color: #0267bf;
}
body .p-datatable .p-datatable-data tr.p-state-highlight {
    border-color: #0267bf;
}
body .p-datatable .p-datatable-data.p-datatable-hoverable-rows > tr.p-widget-content:not(.p-state-highlight):hover {
    background-color: #e3e3e3;
    cursor: pointer;
}
body .p-datatable .p-datatable-data tr.p-row-editing.p-datatable-odd,
body .p-datatable .p-datatable-data tr.p-row-editing.p-datatable-even {
    border-color: #c7c7c7;
    border-bottom-color: #0275d8;
}
body .p-datatable .p-datatable-data > tr > td {
    padding: .75em;
}
body .p-datatable .p-datatable-data tr td.p-editable-column input {
    width: 100%;
    box-sizing: border-box;
    border: 0 none;
    -moz-border-radius: 0;
    -webkit-border-radius: 0;
    border-radius: 0;
}
body .p-datatable .p-datatable-scrollable-header,
body .p-datatable .p-datatable-scrollable-footer {
    background-color: #f5f5f5;
}
body .p-datatable .p-datatable-scrollable-header .p-datatable-scrollable-header-box td,
body .p-datatable .p-datatable-scrollable-footer .p-datatable-scrollable-header-box td {
    color: #373a3c;
}
body .p-datatable .p-datatable-data tr.p-rowgroup-header {
    font-weight: bold;
}
body .p-datatable .p-rowgroup-header {
    color: #2d353c;
}

body .p-datatable .p-rowgroup-header a {
    color: #2d353c;
}

body .p-datagrid .p-datagrid-header {
    padding: .75em 1.25em;
    background-color: #f5f5f5;
}
body .p-datagrid .p-paginator {
    padding: 0;
}
body .p-datalist .p-datalist-header {
    padding: .75em 1.25em;
    background-color: #f5f5f5;
}
body .p-datalist .p-paginator {
    padding: 0;
}
body .p-datascroller .p-datascroller-header {
    padding: .75em 1.25em;
    background-color: #f5f5f5;
}
body .p-picklist .p-picklist-buttons button {
    font-size: 1.1em;
}
body .p-picklist .p-picklist-caption {
    padding: .75em 1.25em;
    background-color: #f5f5f5;
}
body .p-picklist .p-picklist-filter-container {
    margin-bottom: 0.063em;
}
body .p-picklist .p-picklist-filter-container .p-icon {
    top: 0.313em;
    right: 0.25em;
    color: #747a7f;
}
body .p-picklist .p-picklist-list .p-picklist-item {
    padding: 0.375em 0.75em;
    margin: 0;
    -moz-border-radius: 0;
    -webkit-border-radius: 0;
    border-radius: 0;
    border-bottom: 1px solid rgba(0, 0, 0, 0.15);
}
body .p-picklist .p-picklist-list .p-picklist-item:not(.p-state-highlight):hover {
    background-color: #f4f3f4;
    color: #373a3c;
}
body .p-picklist .p-picklist-list .p-picklist-item.p-state-highlight {
    background-color: #0275d8;
    color: #ffffff;
}
body .p-orderlist .p-orderlist-controls button {
    font-size: 1.1em;
}
body .p-orderlist .p-orderlist-caption {
    padding: .75em 1.25em;
    background-color: #f5f5f5;
    box-sizing: border-box;
    width: 12.5em;
}
body .p-orderlist .p-orderlist-list {
    box-sizing: border-box;
}
body .p-orderlist .p-orderlist-list .p-orderlist-item {
    padding: 0.375em 0.75em;
    margin: 0;
    -moz-border-radius: 0;
    -webkit-border-radius: 0;
    border-radius: 0;
    border-bottom: 1px solid rgba(0, 0, 0, 0.15);
}
body .p-orderlist .p-orderlist-list .p-orderlist-item:last-child {
    border-bottom: none;
}
body .p-orderlist .p-orderlist-list .p-orderlist-item:not(.p-state-highlight):hover {
    background-color: #f4f3f4;
    color: #373a3c;
}
body .p-orderlist .p-orderlist-list .p-orderlist-item.p-state-highlight {
    background-color: #0275d8;
    color: #ffffff;
}
body .p-orderlist.p-grid-responsive .p-grid-row .p-orderlist-controls {
    margin-right: 0;
    padding-right: 0.5em;
}
body .p-carousel {
    padding: 0;
}
body .p-carousel .p-carousel-header {
    margin: 0;
    border: 0 none;
    border-bottom: 1px solid #e6e6e6;
    padding: .75em 1.25em;
    background-color: #f5f5f5;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}
body .p-carousel .p-carousel-header .p-carousel-header-title {
    padding: 0;
}
body .p-carousel .p-carousel-footer {
    border: 0 none;
    border-top: 1px solid #e6e6e6;
    margin: 0;
    -moz-border-radius: 0;
    -webkit-border-radius: 0;
    border-radius: 0;
    padding: .75em 1.25em;
    background-color: #f5f5f5;
}
body .p-carousel .p-carousel-button {
    margin-top: 0.2em;
    -moz-transition: color 0.3s;
    -o-transition: color 0.3s;
    -webkit-transition: color 0.3s;
    transition: color 0.3s;
    color: #767b7f;
}
body .p-carousel .p-carousel-button:hover {
    color: #2d353c;
}
body .p-carousel .p-carousel-button.p-state-disabled:hover {
    color: #2d353c;
}
body .p-carousel .p-carousel-page-links {
    margin-top: 0.188em;
}
body .p-carousel .p-carousel-page-links .p-carousel-page-link {
    color: #767b7f;
}
body .p-carousel .p-carousel-page-links .p-carousel-page-link.fa-circle-o {
    background: none !important;
    display: inline-block;
    font: normal normal normal 14px/1 FontAwesome;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-indent: 0px !important;
    text-align: center;
}
body .p-carousel .p-carousel-page-links .p-carousel-page-link.fa-circle-o::before {
    content: "";
}
body .p-carousel .p-carousel-page-links .p-carousel-page-link.fa-dot-circle-o {
    background: none !important;
    display: inline-block;
    font: normal normal normal 14px/1 FontAwesome;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-indent: 0px !important;
    text-align: center;
}
body .p-carousel .p-carousel-page-links .p-carousel-page-link.fa-dot-circle-o::before {
    content: "";
}
body .p-carousel .p-carousel-page-links .p-carousel-page-link.p-icon-radio-on {
    color: #0275d8;
    background-color: #767b7f;
}
body .p-treetable .p-treetable-header {
    padding: .75em 1.25em;
    background-color: #f5f5f5;
}
body .p-treetable .p-treetable-footer {
    padding: .75em 1.25em;
    background-color: #f5f5f5;
}
body .p-treetable .p-paginator {
    padding: 0.375em 0.75em;
}
body .p-treetable thead th {
    background-color: #ffffff;
    padding: .75em;
    border-color: #c7c7c7;
    color: #373a3c;
}
body .p-treetable thead th.p-state-active {
    border-color: #0267bf;
    background-color: #0275d8;
    color: #ffffff;
}
body .p-treetable tfoot td {
    background-color: #ffffff;
    padding: .75em;
    border-color: #e6e6e6;
}
body .p-treetable .p-treetable-data tr:not(.p-state-highlight):hover {
    background-color: #e3e3e3;
}
body .p-treetable .p-treetable-row.p-state-highlight .p-chkbox-icon {
    color: #555555;
    margin: 0;
}
body .p-treetable .p-treetable-row.p-state-highlight .p-treetable-toggler {
    color: #ffffff;
}
body .p-treetable .p-treetable-row.p-treetable-row-selectable:not(.p-state-highlight):hover {
    background-color: #e3e3e3;
}
body .p-treetable .p-treetable-data tr.p-state-highlight {
    border-color: #0267bf;
}
body .p-treetable .p-treetable-data tr td .p-treetable-toggler {
    font-size: 3em;
    vertical-align: middle;
    display: inline-block;
    position: relative;
}
body .p-treetable .p-treetable-data tr td .p-chkbox {
    vertical-align: middle;
    display: inline-block;
    position: relative;
}
body .p-treetable tbody td{
    padding: .75em;
}
body .p-treetable .p-treetable-data tr td .p-chkbox .p-chkbox-icon {
    color: #0275d8;
    left: 0px;
}
body .p-treetable .p-treetable-scrollable-header,
body .p-treetable .p-treetable-scrollable-footer {
    background-color: #f0f3f5;
}
body .p-treetable .p-treetable-scrollable-header .p-treetable-scrollable-header-box td,
body .p-treetable .p-treetable-scrollable-footer .p-treetable-scrollable-header-box td {
    color: #373a3c;
}
body .p-treetable .p-treetable-scrollable-body::-webkit-scrollbar {
    background-color: #f0f3f5;
}
body .p-tree {
    box-sizing: border-box;
}
body .p-tree .p-treenode .p-treenode-content .p-tree-toggler {
    font-size: 1.1em;
    vertical-align: middle;
    display: inline-block;
    color: #747a7f;
    margin-top: 0.2em;
}
body .p-tree .p-treenode .p-treenode-content .p-treenode-icon {
    vertical-align: middle;
    display: inline-block;
    margin: 0.2em 0.375em 0 0;
    font-size: 1.1em;
    color: #747a7f;
}
body .p-tree .p-treenode .p-treenode-content .p-treenode-label {
    margin: 0;
    vertical-align: middle;
    display: inline-block;
    padding: 0;
}
body .p-tree.p-tree-selectable .p-treenode .p-treenode-content .p-treenode-label:not(.p-state-highlight):hover {
    background-color: #f4f3f4;
    color: #373a3c;
}
body .p-tree .p-treenode .p-treenode-content .p-treenode-label.p-state-highlight {
    background-color: #0275d8;
    color: #ffffff;
}
body .p-tree .p-tree-droppoint:hover {
    background-color: #0275d8;
}
body .p-tree.p-tree-horizontal .p-treenode-content {
    background-color: #ffffff;
    border: 1px solid #c7c7c7;
}
body .p-tree.p-tree-horizontal .p-treenode-content.p-treenode-selectable .p-treenode-label:not(.p-state-highlight):hover {
    background-color: inherit;
    color: inherit;
}
body .p-tree.p-tree-horizontal .p-treenode-content.p-treenode-selectable:not(.p-state-highlight):hover {
    background-color: #f4f3f4;
    color: #373a3c;
}
body .p-tree.p-tree-horizontal .p-treenode-content.p-state-highlight {
    background-color: #0275d8;
    color: #ffffff;
}
body .p-tree.p-tree-horizontal .p-treenode-content.p-state-highlight .p-tree-toggler,
body .p-tree.p-tree-horizontal .p-treenode-content.p-state-highlight .p-treenode-icon {
    color: #ffffff;
}
body .p-tree.p-tree-horizontal .p-treenode-content .p-chkbox .p-icon {
    color: #0275d8;
}
body .p-tree-draghelper {
    border: 1px solid #0275d8;
}
body .fc .fc-event {
    background-color: #025aa5;
    border-color: #025aa5;
}
body .fc .fc-slats td {
    background-color: #ffffff;
}
body .fc .fc-toolbar .fc-prev-button span {
    line-height: inherit;
    background: none !important;
    display: inline-block;
    font: normal normal normal 14px/1 FontAwesome;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-indent: 0px !important;
    text-align: center;
}
body .fc .fc-toolbar .fc-prev-button span::before {
    content: "";
}
body .fc .fc-toolbar .fc-prev-button .p-icon {
    position: relative;
    top: 0.186em;
}
body .fc .fc-toolbar .fc-next-button span {
    line-height: inherit;
    background: none !important;
    display: inline-block;
    font: normal normal normal 14px/1 FontAwesome;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-indent: 0px !important;
    text-align: center;
}
body .fc .fc-toolbar .fc-next-button span::before {
    content: "";
}
body .fc .fc-toolbar .fc-next-button .p-icon {
    position: relative;
    top: 0.186em;
}


body .p-tooltip .p-tooltip-text {
    -webkit-box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.3);
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.3);
}
body .p-tooltip.p-state-error {
    border: 0 none;
}
body .p-lightbox {
    -webkit-box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.3);
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.3);
}
body .p-lightbox .p-lightbox-caption {
    padding: 0.375em 0.75em;
}
body .p-lightbox .p-lightbox-caption .p-lightbox-caption-text {
    color: #2d353c;
    margin: 0;
}
body .p-lightbox .p-lightbox-caption .p-lightbox-close {
    padding: 0;
    color: #2d353c;
    -moz-transition: color 0.3s;
    -o-transition: color 0.3s;
    -webkit-transition: color 0.3s;
    transition: color 0.3s;
}
body .p-lightbox .p-lightbox-caption .p-lightbox-close:hover {
    color: #0275d8;
}
body .p-lightbox .p-lightbox-content-wrapper {
    overflow: hidden;
}
body .p-lightbox .p-lightbox-content-wrapper .p-lightbox-nav-left {
    background: none !important;
    display: inline-block;
    font: normal normal normal 14px/1 FontAwesome;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-indent: 0px !important;
    text-align: center;
    -moz-transition: all 0.3s;
    -o-transition: all 0.3s;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
    font-size: 1.5em;
    margin-left: 0.25em;
}
body .p-lightbox .p-lightbox-content-wrapper .p-lightbox-nav-left::before {
    content: "";
}
body .p-lightbox .p-lightbox-content-wrapper .p-lightbox-nav-left:hover {
    -webkit-transform: scale(1.2);
    -moz-transform: scale(1.2);
    -o-transform: scale(1.2);
    -ms-transform: scale(1.2);
    transform: scale(1.2);
}
body .p-lightbox .p-lightbox-content-wrapper .p-lightbox-nav-left span {
    display: none;
}
body .p-lightbox .p-lightbox-content-wrapper .p-lightbox-nav-right {
    background: none !important;
    display: inline-block;
    font: normal normal normal 14px/1 FontAwesome;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-indent: 0px !important;
    text-align: center;
    -moz-transition: all 0.3s;
    -o-transition: all 0.3s;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
    font-size: 1.5em;
    margin-right: 0.25em;
}
body .p-lightbox .p-lightbox-content-wrapper .p-lightbox-nav-right::before {
    content: "";
}
body .p-lightbox .p-lightbox-content-wrapper .p-lightbox-nav-right:hover {
    -webkit-transform: scale(1.2);
    -moz-transform: scale(1.2);
    -o-transform: scale(1.2);
    -ms-transform: scale(1.2);
    transform: scale(1.2);
}
body .p-lightbox .p-lightbox-content-wrapper .p-lightbox-nav-right span {
    display: none;
}
body .p-lightbox .p-lightbox-content-wrapper .p-lightbox-content.p-lightbox-loading + a {
    display: none;
}
body .p-breadcrumb {
    background-color: #ffffff;
    padding: 0.375em 0.75em;
}
body .p-breadcrumb ul li {
    float: none;
    display: inline-block;
    vertical-align: middle;
}
body .p-breadcrumb ul li .p-menuitem-link {
    color: #0275d8;
    margin: 0;
    position: relative;
    top: 0;
}
body .p-breadcrumb ul li.p-breadcrumb-chevron {
    font-size: 1em;
    margin: 0.25em 0.375em 0 0.375em;
    color: #767b7f;
}
body .p-breadcrumb ul li.p-breadcrumb-chevron.fa-chevron-right::before {
    content: "/";
    font-size: 1.375em;
    font-weight: 700;
}
body .p-breadcrumb ul li:first-child {
    font-size: 1.2em;
}
body .p-breadcrumb ul li:first-child a.p-icon-home {
    color: #767b7f;
    margin: 0;
}
body .p-steps {
    position: relative;
}
body .p-steps .p-steps-item {
    background-color: transparent;
    text-align: center;
}
body .p-steps .p-steps-item.p-state-disabled {
    opacity: 1;
    filter: alpha(opacity=100);
}
body .p-steps .p-steps-item .p-menuitem-link {
    display: inline-block;
    text-align: center;
    background-color: transparent;
    overflow: hidden;
}
body .p-steps .p-steps-item .p-menuitem-link .p-steps-number {
    display: inline-block;
    background-color: #ffffff;
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
    border-radius: 50%;
    padding: 0.25em 0.563em;
    color: #747a7f;
    position: relative;
    width: 28px;
    margin-top: 0;
    font-size: 1em;
    top: 0.875em;
    margin-bottom: 0.75em;
    border: 1px solid #cccccc;
}
body .p-steps .p-steps-item .p-menuitem-link .p-steps-title {
    display: block;
    margin-top: 0.375em;
    color: #747a7f;
}
body .p-steps .p-steps-item.p-state-highlight .p-steps-number {
    background-color: #0275d8;
    color: #ffffff;
    border-color: #0275d8;
}
body .p-steps .p-steps-item:not(.p-state-highlight) .p-steps-number:hover {
    background-color: #e6e6e6;
}
body .p-steps .p-steps-item.p-state-highlight .p-steps-title {
    font-weight: 700;
    color: #373a3c;
}
body .p-steps .p-steps-item:last-child .p-menuitem-link {
    display: block;
}
body .p-steps::before {
    content: ' ';
    border: 1px solid #0275d8;
    width: 100%;
    top: 45%;
    left: 0;
    display: block;
    position: absolute;
}
body .p-menu {
    padding: 0;
}
body .p-menu.p-shadow {
    -webkit-box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.3);
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.3);
}
body .p-menu .p-menu-parent .p-submenu-icon {
    margin-top: 0.2em;
}
body .p-menu .p-menu-list .p-widget-header {
    width: 100%;
    box-sizing: border-box;
    border: 0 none;
    background: #e6e6e6;
    -moz-border-radius: 0;
    -webkit-border-radius: 0;
    border-radius: 0;
}
body .p-menu .p-menu-list .p-widget-header h3 {
    padding: 0.375em 0.75em;
    font-weight: 400;
}
body .p-menu .p-menu-list .p-widget-header .p-icon {
    color: #767b7f;
}
body .p-menu .p-menu-list .p-widget-header:hover {
    background-color: #e6e6e6;
}
body .p-menu .p-menu-list .p-widget-header:hover .p-icon {
    color: #2d353c;
}
body .p-menu .p-menu-list .p-widget-header:first-child {
    margin-top: 0;
}
body .p-menu .p-menu-list .p-menuitem {
    margin: 0;
    box-sizing: border-box;
    -moz-border-radius: 0;
    -webkit-border-radius: 0;
    border-radius: 0;
}
body .p-menu .p-menu-list .p-menuitem .p-menuitem-link {
    padding: 0.5em 0.75em;
    width: 100%;
    box-sizing: border-box;
    -moz-border-radius: 0;
    -webkit-border-radius: 0;
    border-radius: 0;
    color: #373a3c;
}
body .p-menu .p-menu-list .p-menuitem .p-menuitem-link:hover,
body .p-menu .p-menu-list .p-menuitem.p-menuitem-active > .p-menuitem-link {
    background-color: #0275d8;
    color: #ffffff;
}
body .p-menu .p-menu-list .p-menu-child {
    padding: 0;
}
body .p-menu .p-menu-list .p-menu-child.p-shadow {
    -webkit-box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.3);
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.3);
}
body .p-menu.p-menubar {
    background-color: #ffffff;
    border-color: #e6e6e6;
}
body .p-menu.p-menubar > .p-menu-list > .p-menuitem {
    width: auto;
}
body .p-menu.p-menubar > .p-menu-list > .p-menuitem > .p-menuitem-link {
    color: #2d353c;
}
body .p-menu.p-menubar > .p-menu-list > .p-menuitem > .p-menuitem-link .p-menuitem-icon {
    color: #767b7f;
}
body .p-menu.p-menubar > .p-menu-list > .p-menuitem > .p-menuitem-link:hover {
    color: #ffffff;
}
body .p-menu.p-menubar > .p-menu-list > .p-menuitem > .p-menuitem-link:hover .p-menuitem-icon {
    color: #ffffff;
}
body .p-menu.p-menubar > .p-menu-list > .p-menuitem.p-menubar-options {
    margin-right: 8px;
}
body .p-menu.p-megamenu .p-menu-list > table > tbody > tr > td {
    padding: 0;
}
body .p-menu.p-megamenu.p-megamenu-vertical > .p-menu-list .p-menuitem {
    width: 100%;
}
body .p-tabmenu {
    border: 0 none;
}
body .p-tabmenu .p-tabmenu-nav {
    padding: 0;
}
body .p-tabmenu .p-tabmenu-nav .p-tabmenuitem {
    margin: 0;
    position: static;
    -moz-transition: background-color 0.3s;
    -o-transition: background-color 0.3s;
    -webkit-transition: background-color 0.3s;
    transition: background-color 0.3s;
    -moz-border-radius: 0;
    -webkit-border-radius: 0;
    border-radius: 0;
}
body .p-tabmenu .p-tabmenu-nav .p-tabmenuitem .p-menuitem-link {
    color: #2d353c;
    padding: 0.375em 0.75em;
}
body .p-tabmenu .p-tabmenu-nav .p-tabmenuitem .p-menuitem-link .p-menuitem-icon,
body .p-tabmenu .p-tabmenu-nav .p-tabmenuitem .p-menuitem-link .p-menuitem-text {
    vertical-align: middle;
    display: inline-block;
    float: none;
}
body .p-tabmenu .p-tabmenu-nav .p-tabmenuitem .p-menuitem-link .p-menuitem-icon {
    color: #767b7f;
}
body .p-tabmenu .p-tabmenu-nav .p-tabmenuitem:not(.p-state-active):hover {
    background-color: #e6e6e6;
}
body .p-tabmenu .p-tabmenu-nav .p-tabmenuitem:hover .p-menuitem-icon {
    color: #2d353c;
}
body .p-tabmenu .p-tabmenu-nav .p-tabmenuitem.p-state-active .p-menuitem-link {
    background-color: #0275d8;
    color: #ffffff;
}
body .p-tabmenu .p-tabmenu-nav .p-tabmenuitem.p-state-active .p-menuitem-link .p-menuitem-icon {
    color: #ffffff;
}
body .p-menubutton .p-button.p-button-text-icon-left .p-icon {
    margin-top: -.625em;
}
body .p-panelmenu .fa {
    position: static;
}
body .p-panelmenu .p-panelmenu-panel {
    border: 1px solid #c7c7c7;
    border-bottom: none;
}
body .p-panelmenu .p-panelmenu-panel:last-child {
    border-bottom: 1px solid #c7c7c7;
}
body .p-panelmenu .p-panelmenu-header {
    background-color: #ffffff;
    padding: 0;
    font-size: 1em;
    color: #2d353c;
    -moz-transition: background-color 0.3s;
    -o-transition: background-color 0.3s;
    -webkit-transition: background-color 0.3s;
    transition: background-color 0.3s;
}
body .p-panelmenu .p-panelmenu-header .fa {
    color: #767b7f;
}
body .p-panelmenu .p-panelmenu-header a {
    padding: 0.5em 0.75em;
    color: #2d353c;
}
body .p-panelmenu .p-panelmenu-header:not(.p-state-active):hover {
    background-color: #e6e6e6;
}
body .p-panelmenu .p-panelmenu-header:not(.p-state-active):hover .fa {
    color: #2d353c;
}
body .p-panelmenu .p-panelmenu-header.p-state-active {
    background-color: #0275d8;
}
body .p-panelmenu .p-panelmenu-header.p-state-active a {
    color: #ffffff;
}
body .p-panelmenu .p-panelmenu-header.p-state-active .fa {
    color: #ffffff;
}
body .p-panelmenu .p-panelmenu-content .p-menuitem-link {
    padding: 0.375em 0.75em;
    color: #373a3c;
    -moz-transition: background-color 0.3s;
    -o-transition: background-color 0.3s;
    -webkit-transition: background-color 0.3s;
    transition: background-color 0.3s;
    -moz-border-radius: 0;
    -webkit-border-radius: 0;
    border-radius: 0;
}
body .p-panelmenu .p-panelmenu-content .p-menuitem-link:hover {
    background-color: #f4f3f4;
    color: #373a3c;
}
body .p-panelmenu .p-panelmenu-content .p-menuitem-link > span.fa {
    display: inline-block;
    vertical-align: middle;
    left: auto;
}
body .p-panelmenu .p-panelmenu-content .p-menuitem-link .p-menuitem-text {
    display: inline-block;
    vertical-align: middle;
}
body .p-progressbar {
    border: 0 none;
    background-color: #e3e3e3;
}
body .p-progressbar .p-progressbar-value {
    border: 0 none;
    background: #299bfd;
    margin: 0;
    -moz-border-radius: 0;
    -webkit-border-radius: 0;
    border-radius: 0;
}
body .p-progressbar .p-progressbar-label {
    margin-top: -0.063em;
}
body .p-galleria .p-galleria-nav-prev {
    font-size: 1em;
    color: #767b7f;
    -moz-transition: color 0.3s;
    -o-transition: color 0.3s;
    -webkit-transition: color 0.3s;
    transition: color 0.3s;
}
body .p-galleria .p-galleria-nav-prev:hover {
    color: #2d353c;
}
body .p-galleria .p-galleria-nav-next {
    font-size: 1em;
    color: #767b7f;
    -moz-transition: color 0.3s;
    -o-transition: color 0.3s;
    -webkit-transition: color 0.3s;
    transition: color 0.3s;
}
body .p-galleria .p-galleria-nav-next:hover {
    color: #2d353c;
}

body .p-slidemenu .p-slidemenu-backward {
    border: 0 none;
    background-color: #f5f5f5;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}

body .p-inputgroup .p-inputgroup-addon {
  border-color: rgba(0,0,0,.15);
  background-color: #eceeef;
  color: #55595c;
  padding: 0.5em 0.75em;
}

body  .p-inputgroup .p-inputgroup-addon:first-child {
    border-top-left-radius: 0.25em;
    border-bottom-left-radius: 0.25em; 
}
 
body .p-inputgroup .p-inputgroup-addon:last-child {
    border-top-right-radius: 0.25em;
    border-bottom-right-radius: 0.25em;
}

body .p-inputgroup .p-button:first-child {
  border-top-left-radius: 0.25em;
  border-bottom-left-radius: 0.25em;

}
.p-inputgroup .p-button:last-child {
  border-top-right-radius: 0.25em;
  border-bottom-right-radius: 0.25em;
}