.col-lg-1, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-md-1, .col-md-10, .col-md-11, .col-md-12, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-sm-1, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-xs-1, .col-xs-10, .col-xs-11, .col-xs-12, .col-xs-2, .col-xs-3, .col-xs-4, .col-xs-5, .col-xs-6, .col-xs-7, .col-xs-8, .col-xs-9 {
    position: relative !important;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;
}

label {
    display: inline-block;
    max-width: 100%;
    margin-bottom: 5px;
    font-weight: 700;
    float: left !important;
}
.form-group {
    margin-bottom: 15px;
}

.dropdown
{
    float: right!important;
}

nav.navbar-default .nav a.onesearch-button .glyphicon {
    font-size: 29px;
    line-height: 34px;
    display: block;
}

[class*=" glyphicon-"], [class^=glyphicon-] {
    font-family: ChevronIcons;
    speak: none;
    font-style: normal;
    font-weight: 400;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}


.visually-hidden {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0,0,0,0);
    border: 0;
}

nav.navbar-default .nav a.onesearch-button .icon-text {
    font-size: .75em;
    line-height: 1em;
    font-weight: 400;
    font-family: GothamBookMedium,"Helvetica Neue",Helvetica,Arial,sans-serif;
}

nav.navbar-default .dropdown-large.search {
    margin: 24px 3px 0;
}

nav.navbar-default .nav>li, nav.navbar-subsite .nav>li {
    line-height: 1.45em;
}

nav.navbar-default .dropdown-large {
    position: static!important;
    margin: 28px 12px 0;
}

.pull-right {
    float: right!important;
}


 
.vertical-align {
    display: flex;
    /* align-items: center; */
    justify-content: center;
    flex-direction: row;
}

.centered {
    float: none;
    margin: 0 auto;
}

.dropdown-menu {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1000;
    display: none;
    float: left;
    min-width: 160px;
    padding: 5px 0;
    margin: 2px 0 0;
    font-size: 14px;
    text-align: left;
    list-style: none;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0,0,0,.15);
    border-radius: 4px;
    box-shadow: 0 6px 12px rgba(0,0,0,.175);
}